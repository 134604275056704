import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Helmet from "react-helmet"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import ContactForm from "../../components/contact-us-form"
import PracticeAreas from "../../components/practice-areas"
import { Container, Row, Col } from "react-bootstrap"
// keywords: birmingham personal injury lawyer
export default ({ data }) => (
	<Layout style={{ color: `teal` }}>
		<Helmet title="Personal Injury Lawyers | RWK Attorneys" defer={false} />

		<section style={{
			backgroundImage: "linear-gradient(90deg, rgba(255,255,255,1) 20%, rgba(255,255,255,0)), url('/cover.webp')",
			backgroundSize: "cover",
			backgroundPosition: "center",
		}}>

			<div className="container text-center text-md-left d-flex justify-content-start align-items-center" style={{ minHeight: "40vh" }}>
				<div className="row">
					<div className="col">
						<nav aria-label="breadcrumb">
							<ol className="breadcrumb pb-0">
								<li class="breadcrumb-item"><Link to="/">Home</Link></li>
								<li class="breadcrumb-item"><Link to="/practice-areas">Practice Areas</Link></li>
								<li class="breadcrumb-item active" aria-current="page">Personal Injury</li>
							</ol>
						</nav>
						<h1>Personal Injury</h1>
						{/* <p className="lead">With over 75 years of combined legal experience serving residents across the State of Alabama, particularly in Jefferson, St. Clair, Blount and Shelby counties.</p> */}
						{/* <p className="lead">Serving residents of Alabama<br /> with over 75 years of<br /> combined legal experience</p> */}
						{/* <p><Link className="btn btn-primary" to="/practice-areas/#pa">Learn More</Link></p> */}
					</div>
				</div>
			</div>
		</section>


		<Container className="my-5">
			<Row>
				<Col md="3">

					<PracticeAreas />

					<ContactForm />
				</Col>

				<Col className="mt-5 mt-sm-0">
					<h2>Helping Injury Victims Get Justice In Alabama</h2>
					<p>We are dedicated to protecting the rights and interests of those who have been hurt due to the negligence or recklessness of another party. We provide free, no-obligation consultations. Please call our Birmingham personal injury attorneys or fill out our online contact form today.</p>

					<p>It's natural to be concerned about how you’ll pay for the cost of treatment and recoup wages that you’ve lost, among other expenses you’ve likely incurred if you have been hurt due to someone else’s recklessness or negligence in Alabama. The purpose of personal injury law is to return injured victims to the position they were in prior to the unfortunate accident. We know that in practice, this is difficult and nearly impossible, but using the legal system and with the right personal injury lawyers on your side, we can help you get as close as possible. Every case is different, but we strive to ensure justice prevails.</p>
					
					<p>We handle many types of personal injury cases such as:</p>
					<ul>
						<li>Automobile Accidents</li>
						<li>Defective Products</li>
						<li>Drug Injuries</li>
						<li>Semi-trailer Truck Accidents</li>
						<li>Wrongful Death</li>
					</ul>

					<p>If you have been injured in an accident or have been injured due to a defective product or from taking certain drugs, we would like to speak with you to see if we can assist you.  Contact us today for a free consultation.</p>

				</Col>
			</Row>
		</Container>
	</Layout>
)